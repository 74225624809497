<template>
    <div class="Awarp">
        <dashCard class="lvscBox1" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('lvscBox1_roomEnviro','配电室环境', getZEdata)}}</template>
            <template slot="aside"></template>
            <div class="card-content1">
                <a-space direction="vertical" style="width:100%">
                    <a-row type="flex" justify="space-between" align="bottom">
                    <a-col :span="8">
                        <div class="content-head">
                        <span>{{commonLangHandler('lvscBox1_temp','温度', getZEdata)}}</span>
                        <span>
                            {{detailInfo.temperature}}
                            <sub>℃</sub>
                        </span>
                    </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="content-head">
                        <span>{{commonLangHandler('lvscBox1_humidity','湿度', getZEdata)}}</span>
                        <span>
                            {{detailInfo.humidity}}
                            <sub>%</sub>
                        </span>
                    </div>
                    </a-col>
                    <a-col :span="8">
                        <div class="content-head">
                        <span>{{commonLangHandler('lvscBox1_waterLevel','水位', getZEdata)}}</span>
                        <span>
                            {{detailInfo.waterlevel}}
                            <sub>m</sub>
                        </span>
                    </div>
                    </a-col>
                </a-row>
                <a-row type="flex" justify="space-between" align="bottom" class="content-substance">
                    <a-col :span="8" v-for="(item,idx) in detailInfo.list" :key="idx" class="content-substance-item">
                        <span >
                            <i :class="{'warn-i': item.status=='warning','normal-i': item.status=='normal'}"></i>
                            <font>{{ item.name }}</font>
                        </span>

                    </a-col>
                </a-row>
                </a-space>
            </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <lvscBox1  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'lvscBox1',
        components: {
            dashCard: () => import('@/component/dashCard.vue')
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中
                searchObj: {},
                detailInfo: {
                    temperature: '22.23',
                    humidity: '46.18',
                    waterlevel: '0',
                    list: [
                        {
                            name:'烟感报警',
                            status: 'warning'
                        },
                        {
                            name:'SF6浓度超限报警',
                            status: 'normal'
                        },
                        {
                            name:'漏水探测报警',
                            status: 'normal'
                        },
                        {
                            name:'门状态信号',
                            status: 'normal'
                        },
                        {
                            name:'摄像头报警',
                            status: 'normal'
                        },
                        {
                            name:'入侵报告',
                            status: 'normal'
                        },
                    ]
                }
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

            //每月报警预警数
            getData() {
                this.isLoading = true
                this.detailInfo = {
                    temperature: '22.23',
                    humidity: '46.18',
                    waterlevel: '0',
                    list: [
                        {
                            name:'烟感报警',
                            status: 'warning'
                        },
                        {
                            name:'SF6浓度超限报警',
                            status: 'normal'
                        },
                        {
                            name:'漏水探测报警',
                            status: 'normal'
                        },
                        {
                            name:'门状态信号',
                            status: 'normal'
                        },
                        {
                            name:'摄像头报警',
                            status: 'normal'
                        },
                        {
                            name:'入侵报告',
                            status: 'normal'
                        },
                    ]
                }
                this.isLoading = false
            },
            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.lvscBox1 {
    .card-content1 {
        .content-head {
                display: flex;
                flex-direction: column;
                span {
                    &:first-child {
                        font-size: 14px;
                        font-family: ABBvoice_WCNSG_Rg;
                        font-weight: 400;
                        text-align: left;
                        color: rgba(0,0,0,0.65);
                        line-height: 22px;
                        margin-bottom: 2px;
                    }
                    &:nth-child(2) {
                        font-size: 36px;
                        font-family: ABBvoiceCNSG, ABBvoiceCNSG-Bold;
                        font-weight: 700;
                        text-align: left;
                        color: #1f1f1f;
                        line-height: 44px;
                    }
                }
                sub {
                    font-size: 12px;
                    font-family: ABBvoice_WCNSG_Rg;
                    font-weight: 400;
                    color: rgba(0,0,0,0.45);
                    line-height: 20px;
                    vertical-align: middle;
                    margin-left: 6px;
                }
            }
            .content-substance {
                margin-top: 10px;
                .content-substance-item {
                    margin-top: 16px;
                }
                    i {
                        width: 10px;
                        height: 10px;
                        border-radius: 2px;
                        display: inline-block;
                    }
                    font {
                        font-size: 12px;
                        font-family: ABBvoice_WCNSG_Rg;
                        font-weight: 400;
                        color: #696969;
                        line-height: 20px;
                        margin-left: 10px;
                    }
                    .warn-i {
                        background: #f03040;
                    }
                    .normal-i {
                        background: #51A34B;
                    }

            }

    }
   
}
</style>